import { Images } from "../../images";
import { Container, LoadingIcon } from "./styles";

const LoadingPage = () => {
  return (
    <Container>
      <LoadingIcon src={Images.Logo} />
    </Container>
  );
};

export default LoadingPage;
