import Logo from "./assets/logo.png";
import Logo2 from "./assets/logo-2.png";
import Logo3 from "./assets/logo-3.png";
import ParallelLogo from "./assets/parallel-logo.png"
import OpenseaWhite from "./assets/opensea-white.svg";
import OpenseaBlue from "./assets/opensea-blue.svg";
import Globe from "./assets/globe-icon.png";
import ProjectImg1 from "./assets/project-img-1.jpg";
import ProjectImg2 from "./assets/project-img-2.jpg";
import Parallel from "./assets/parallel.jpg"
import Checked from './assets/checked.svg'
import DividerYellow from "./assets/divider-yellow.png";
import HighlightIcon from "./assets/highlight-icon.png";
import ArtistImage from "./assets/artist-image.png";
import AtomicLogo from "./assets/atomic-logo.png";
import FaqIcon from "./assets/faq-icon.png";
import MintBorder from "./assets/mint-border.png";
import MintBorderHover from "./assets/mint-border-hover.png";
import BackgroundMusic from "./assets/background_music.mp3"
import OpenSeaLogo from "./assets/opensea.png"

// Backgrounds
import BG1 from "./assets/bg-1.png";
import BG2 from "./assets/bg-2.png";
import BG3 from "./assets/bg-3.png";
import BG4 from "./assets/bg-4.png";
import BG5 from "./assets/bg-5.png";
import BG6 from "./assets/bg-6.png";
import BG7 from "./assets/bg-7.png";
import FooterBg from "./assets/footer-bg.png";

// Roadmap Icons
import RM1 from "./assets/roadmap-icon-1.png";
import RM2 from "./assets/roadmap-icon-2.png";
import RM3 from "./assets/roadmap-icon-3.png";
import RM4 from "./assets/roadmap-icon-4.png";
import RM5 from "./assets/roadmap-icon-5.png";

// Artist Icons
import AR1 from "./assets/artist-icon-1.png";
import AR2 from "./assets/artist-icon-2.png";
import AR3 from "./assets/artist-icon-3.png";
import AR4 from "./assets/artist-icon-4.png";

// Koun pass path
import KounPassMintPageBG1 from "./assets/koun_pass/bg1.png"
import KounPassMintPageBG2 from "./assets/koun_pass/bg2-clip.png"
import Bell from "./assets/koun_pass/bell.png"
import MintBoard from "./assets/koun_pass/mint_board.png"
import MintBoard2 from "./assets/koun_pass/mint_board2.png"
import KounPassLogo from "./assets/koun_pass/logo.png"
import AVgalleryLogo from "./assets/koun_pass/AVgalleryLogo.png"

import _404LAB from "./assets/communityLogo/404 LAB.jpeg"
import Apsaras from "./assets/communityLogo/Apsaras.jpeg"
import ArtificialIntelligenceClub from "./assets/communityLogo/Artificial Intelligence Club.png"
import AvatarDAO from "./assets/communityLogo/AvatarDAO.jpeg"
import BoldBunny from "./assets/communityLogo/Bold Bunny.jpeg"
import Cat from "./assets/communityLogo/Cat.jpeg"
import ChubbyLittleJiras from "./assets/communityLogo/Chubby Little Jiras.jpeg"
import CloneXCapsule from "./assets/communityLogo/CloneXCapsule.jpeg"
import CosmoChamber from "./assets/communityLogo/Cosmo Chamber.jpeg"
import ElementNFTMarketplace from "./assets/communityLogo/Element NFT Marketplace.jpeg"
import FindTruman from "./assets/communityLogo/FindTruman.jpeg"
import HanFu_NFT from "./assets/communityLogo/HanFu_NFT.jpeg"
import LongzuPASS from "./assets/communityLogo/LongzuPASS.jpeg"
import MIMICSHHANS from "./assets/communityLogo/MIMIC SHHANS.jpeg"
import Metasumeru from "./assets/communityLogo/Metasumeru.jpeg"
import MoonbirdsDynasty from "./assets/communityLogo/Moonbirds Dynasty.jpeg"
import NFTAerfa from "./assets/communityLogo/NFTAerfa.jpeg"
import NFTPlay from "./assets/communityLogo/NFTPlay.jpeg"
import SleepySniperSociety from "./assets/communityLogo/Sleepy Sniper Society.jpeg"
import TSAGuild from "./assets/communityLogo/TSA Guild.jpeg"
import TenGuDaruma from "./assets/communityLogo/TenGuDaruma.jpeg"
import TheZENDAO from "./assets/communityLogo/The ZEN DAO.jpeg"
import X2Y2中文 from "./assets/communityLogo/X2Y2中文.jpeg"
import Yakuzzi from "./assets/communityLogo/Yakuzzi.jpeg"
import 上海青 from "./assets/communityLogo/上海青.jpeg"
import 皇帝肝tangerine from "./assets/communityLogo/皇帝肝tangerine.jpeg"

import DMGDATA from "./assets/communityLogo/DMGDATA.jpeg"
import Soulda from "./assets/communityLogo/Soulda.png"
import Theirverse from "./assets/communityLogo/Theirverse.png"
import WGG from "./assets/communityLogo/WGG.png"

import everland from "./assets/strategyLogo/everland.png"
import MElogo from "./assets/strategyLogo/MElogo.png"

import superrarePoster from "./assets/superrare.jpg"
import ANONYMOUSPoster from "./assets/ANONYMOUS.jpg"

import BellNew from "./assets/koun_pass/bell_new.png"

// Anonymous
import AnonymousAvatar from "./assets/Anonymous/Anonymous.png"
import AnonymousBg from "./assets/Anonymous/bg.jpg"
import Earth from "./assets/Anonymous/earth.png"
import WLCheckBg from './assets/Anonymous/CheckListBackground.mp4';
import AvatarVideo from './assets/Anonymous/AvatarVideo.mp4'

// Kounpass preview
import LiangyuanPreview from "./assets/koun_pass/preview/liangyuan.jpg"
import LiangyuanPreviewHover from "./assets/koun_pass/preview/liangyuan_hover.jpg"
import WubingPreview from "./assets/koun_pass/preview/wubing.jpg"
import WubingPreviewHover from "./assets/koun_pass/preview/wubing_hover.jpg"
import BingqiPreview from "./assets/koun_pass/preview/bingqi.jpg"
import BingqiPreviewHover from "./assets/koun_pass/preview/bingqi_hover.jpg"
import XinguPreview from "./assets/koun_pass/preview/xingu.jpg"
import XinguPreviewHover from "./assets/koun_pass/preview/xingu_hover.jpg"
import JianeiPreview from "./assets/koun_pass/preview/jianei.jpg"
import JianeiPreviewHover from "./assets/koun_pass/preview/jianei_hover.jpg"
import HegePreview from "./assets/koun_pass/preview/hege.jpg"
import HegePreviewHover from "./assets/koun_pass/preview/hege_hover.jpg"
import JiankangPreview from "./assets/koun_pass/preview/jiankang.jpg"
import JiankangPreviewHover from "./assets/koun_pass/preview/jiankang_hover.jpg"
import KaiyunPreview from "./assets/koun_pass/preview/kaiyun.jpg"
import KaiyunPreviewHover from "./assets/koun_pass/preview/kaiyun_hover.jpg"


const communityLogo = [
  _404LAB,
  Apsaras,
  ArtificialIntelligenceClub,
  AvatarDAO,
  BoldBunny,
  Cat,
  ChubbyLittleJiras,
  CloneXCapsule,
  CosmoChamber,
  ElementNFTMarketplace,
  FindTruman,
  HanFu_NFT,
  LongzuPASS,
  MIMICSHHANS,
  Metasumeru,
  MoonbirdsDynasty,
  NFTAerfa,
  NFTPlay,
  SleepySniperSociety,
  TSAGuild,
  TenGuDaruma,
  TheZENDAO,
  X2Y2中文,
  Yakuzzi,
  上海青,
  皇帝肝tangerine,
  DMGDATA,
  Soulda,
  Theirverse,
  WGG
]

const strategyLogo = [
  everland,
  MElogo
]

export const Images = {
  Logo,
  Logo2,
  Logo3,
  OpenseaWhite,
  OpenseaBlue,
  Globe,
  ProjectImg1,
  ProjectImg2,
  DividerYellow,
  HighlightIcon,
  ArtistImage,
  AtomicLogo,
  FaqIcon,
  FooterBg,
  MintBorder,
  MintBorderHover,
  BG1,
  BG2,
  BG3,
  BG4,
  BG5,
  BG6,
  BG7,
  RM1,
  RM2,
  RM3,
  RM4,
  RM5,
  AR1,
  AR2,
  AR3,
  AR4,
  Checked,
  Parallel,
  ParallelLogo,
  KounPassMintPageBG1,
  KounPassMintPageBG2,
  Bell,
  BellNew,
  MintBoard,
  MintBoard2,
  KounPassLogo,
  AVgalleryLogo,
  BackgroundMusic,
  communityLogo,
  strategyLogo,
  OpenSeaLogo,
  superrarePoster,
  ANONYMOUSPoster,
  AnonymousAvatar,
  AnonymousBg,
  Earth,
  WLCheckBg,
  // Previews
  LiangyuanPreview,
  LiangyuanPreviewHover,
  WubingPreview,
  WubingPreviewHover,
  BingqiPreview,
  BingqiPreviewHover,
  XinguPreview,
  XinguPreviewHover,
  JianeiPreview,
  JianeiPreviewHover,
  HegePreview,
  HegePreviewHover,
  JiankangPreview,
  JiankangPreviewHover,
  KaiyunPreview,
  KaiyunPreviewHover,
  AvatarVideo,
};
