import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const LoadingIcon = styled.img`
  width: 15vw;
  animation: ${blink} 2s linear infinite;
`;
